import React from "react"
import {siteMetadata} from '../../gatsby-config'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLink, faPhone, faEnvelope, faAt, faSmile, faCalendar, faComment } from "@fortawesome/free-solid-svg-icons"

const articles = [{
    key: "hostingvertrag",
    title: "Ihr Hostingvertrag",
    articles: [
        {
            key:"webgo-paket-buchen",
            title: "Webgo Tarif",
            description: "Zeigt den Buchvorgang eines Webhosting Tarif bei Webgo."
        },
        {
            key:"aws-account-erstellen",
            title: "Amazon Web Services",
            description: "Legen Sie ein Kundenkonto für den AWS PaaS an."
        },
        {
            key:"careplus",
            class: "careplus",
            link: "/services/careplus",
            title: "WordPress Care+",
            description: "Umfassender Pflege Service exklusiv für unsere Kunden."
        },
    ]
},{
    key: "wordpress-guide",
    title: "WordPress Webseiten - Guide",
    articles: [
        {
            key:"wordpress-login",
            title: "WordPress Login",
            description: "Zeigt das Einloggen zum Bearbeiten der Webseite."
        },
        {
            key:"passwort-vergessen",
            title: "Passwort vergessen",
            description: "Zugriff trotz vergessener Zugangsdaten erhalten."
        },
        {
            key:"seite-anlegen",
            title: "Seite anlegen",
            description: "Eine neue Unterseite in WordPress anlegen."
        },
        {
            key:"seiten-bearbeiten",
            title: "Seiten bearbeiten",
            description: "Eine Unterseite in WordPress bearbeiten."
        },
        {
            key:"elementor",
            title: "Elementor",
            description: "Der WordPress Seiten-Editor im Detail erklärt."
        },
        {
            key:"menues-bearbeiten",
            title: "Menüs bearbeiten",
            description: "Die Navigation - Menüs in WordPress bearbeiten."
        },
        {
            key:"sicherung-und-update",
            title: "Sicherung & Update",
            description: "Halten Sie die Webseite sicher und aktuell."
        },
        {
            key:"mehrsprachige-seiten",
            title: "Mehrsprachige Seiten",
            description: "Seiten in mehreren Sprachen erstellen und verlinken."
        },
        {
            key:"wordpress-adminbar",
            title: "WordPress Adminbar",
            description: "Wechseln Sie schnell zwischen Bearbeitung und Webseite."
        },

    ]
}/*,{
    key: "social-media",
    title: "Soziale Medien",
    articles: [
        {
            key:"instagram-guide",
            title: "Instagram Guide",
            description: "Zeigt den Buchvorgang eines Webhosting Tarif bei Webgo."
        }
    ]
}*/]

const Page = () => (
    <Layout container={false} isDark={true} hasDarkColorScheme={true}>
        <SEO title="Support | Wir helfen gerne!" />

        <div className="container-fluid hero-big hero" style={{backgroundPosition:"bottom center"}} id="support">
            <div className="text-center btn-has-bg">
                <h1>Support</h1>
                <h2 className="text-gray">Alles auf einen Blick.</h2>
                <a href={"tel:"+siteMetadata.phone} className="btn btn-primary-outline mt-2"><FontAwesomeIcon icon={faPhone}/> Anruf</a>
                <a href="https://calendly.com/faktorxmensch/30min?back=0" target="_blank" className="btn btn-primary-outline mt-2"><FontAwesomeIcon icon={faCalendar}/> Termin <span className="hidden-sm">vereinbaren</span></a>
                <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;support%40%7A%69%65%67%65%6E%68%61%67%65%6C%2E%63%6F%6D" className="btn btn-primary-outline mt-2"><FontAwesomeIcon icon={faAt}/> E-mail</a>
            </div>
        </div>

        <div className="embedded-cards">
            <div className="container">


                {articles.map((section,index) => {
                    return (<>
                        <a class="a" name={section.key}></a>
                        <h1>{section.title} <a class="hash" href={"#"+section.key}><FontAwesomeIcon icon={faLink}></FontAwesomeIcon></a></h1>
                        <div class="hstack">{
                            section.articles.map((item,index) => {return (
                                  <Link to={item.link?item.link:"/support/"+item.key} key={index}>
                                      <div className={"card"+(index >= 7 ? " disabled" : item.class ? " card-"+item.class : "" )}>
                                            <picture>
                                                <source srcSet={require("../images/support/"+item.key+"/index.jpg")} type="image/jpg"/>
                                                <img src={require("../images/support/"+item.key+"/index.jpg")}/>
                                            </picture>
                                            <div>
                                                <h1>{item.title}</h1> 
                                                <p>{item.description}</p> 
                                                <a href="#">Mehr efahren ›</a> 
                                          </div>
                                      </div>
                                  </Link>
                                )}
                            )
                        }
                    </div>
                </>)})}


            </div>

        </div>

    </Layout>
)

export default Page
